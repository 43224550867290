
















import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { FETCH_CONTACT_LIST, GET_CONTACT_LIST } from '../store';
import { Contact } from '../types';
import { ApiResponse } from '@/components/types';

const Contact = namespace('contact');

@Component({})
export default class ContactCombobox extends Vue {
  @Prop({ type: String }) value!: string;
  @Prop({ type: Boolean, default: true }) required!: boolean;

  @Contact.Getter(GET_CONTACT_LIST) list!: Contact[];
  @Contact.Action(FETCH_CONTACT_LIST) fetch!: () => Promise<ApiResponse>;

  get contact() {
    return this.value;
  }

  set contact(value: any) {
    if (typeof value === 'object') {
      this.$emit('select', value);
      return;
    }

    this.$emit('input', value);
  }

  get items() {
    return this.list
      .map((contact, index, original) => {
        const counter = original.filter((c) => c.name.trim() === contact.name.trim()).length

        if (counter > 1 && !!contact.street) {
          return { ...contact, label: `${contact.name} (${contact.street})`}
        }

        return { ...contact, label: contact.name }
      })
      .sort((first, second) => first.name.localeCompare(second.name));
  }

  created() {
    return this.fetch();
  }
}
